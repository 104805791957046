import { Progress } from '@swe/shared/ui-kit/components/progress';
import Stack from '@swe/shared/ui-kit/components/stack';

import Text from '@swe/shared/ui-kit/components/text';

import { ceil } from '@swe/shared/utils/number';

import styles from './styles.module.scss';

type LimitProps = {
  name: string;
  current: number;
  max: number;
  abbreviation: string;
};

const Limit = ({ name, current, max, abbreviation }: LimitProps) => {
  const isOutOfLimit = current > max;
  const roundedCurrentLimit = ceil(current, 3);

  return (
    <Stack
      className={isOutOfLimit ? styles._limitExceeded : undefined}
      spacing="sm"
    >
      <Text
        variant="control"
        size="lg"
        className={styles.heading}
      >
        {name}
        <span>
          {roundedCurrentLimit} / {max} {abbreviation}
        </span>
      </Text>
      <Progress
        variant="continuous"
        value={(current / max) * 100}
        errored={isOutOfLimit}
      />
    </Stack>
  );
};

export type { LimitProps };
export { Limit };
export default Limit;

import { useBreakpoint } from '@swe/shared/tools/media';
import { Box } from '@swe/shared/ui-kit/components/box';
import Select, { SelectOption } from '@swe/shared/ui-kit/components/form/select';

import { Loader } from '@swe/shared/ui-kit/components/loader';

import { SectionHeading } from '@swe/shared/ui-kit/components/section-heading';
import cl from 'clsx';

import React, { ReactNode, useCallback, useMemo } from 'react';

import styles from './styles.module.scss';

import CardSummary from 'domains/cart/components/summary/components/card-summary';
import { CaregiverPatient } from 'endpoints/cart/get-caregiver-patients-list';
import { PurchaseLimit } from 'entities/common/orders';
import { ProductSaleType } from 'entities/product/product';

type SummaryProps = {
  limits?: PurchaseLimit[];
  isLoading?: boolean;
  isOutOfLimit?: boolean;
  saleType?: ProductSaleType;
  hasLimits?: boolean;
  checkoutButton?: ReactNode;
  caregiverPatientsSelectOptions?: SelectOption<string>[];
  caregiverHandler: (caregiverId: number) => void;
  patient?: CaregiverPatient;
};

const Summary = ({
  saleType,
  limits = [],
  isLoading = false,
  caregiverPatientsSelectOptions,
  checkoutButton: CheckoutButton,
  isOutOfLimit,
  hasLimits,
  patient,
  caregiverHandler,
}: SummaryProps) => {
  const { desktop } = useBreakpoint();
  const chahngeCaregiverHandler = useCallback(
    (patientId: string) => {
      caregiverHandler(+patientId);
    },
    [caregiverHandler],
  );

  const cardSummaryElement = useMemo(
    () => (
      <CardSummary
        CheckoutButton={CheckoutButton}
        hasLimits={hasLimits}
        isOutOfLimit={isOutOfLimit}
        limits={limits}
        patientName={patient?.name}
        saleType={saleType}
      />
    ),
    [CheckoutButton, hasLimits, isOutOfLimit, limits, patient, saleType],
  );

  const caregiverSummarySectionElement = useMemo(() => {
    return caregiverPatientsSelectOptions ? (
      <>
        <SectionHeading size="md">Order for</SectionHeading>
        <div className={styles.summaryCaregiverContainer}>
          <Select
            name="select"
            options={caregiverPatientsSelectOptions}
            onChange={chahngeCaregiverHandler}
            placeholder="Select from the list"
            value={patient?.id?.toString() || '0'} // Id for me equals 0
            disabled={!caregiverPatientsSelectOptions}
            label={false}
            staticNote={false}
          />
          {cardSummaryElement}
        </div>
      </>
    ) : (
      cardSummaryElement
    );
  }, [caregiverPatientsSelectOptions, chahngeCaregiverHandler, patient?.id, cardSummaryElement]);

  return (
    <Box className={cl(styles.root, !desktop && styles._stretch)}>
      {isLoading ? (
        <Loader
          size="lg"
          centered
        />
      ) : (
        caregiverSummarySectionElement
      )}
    </Box>
  );
};

export type { SummaryProps };
export { Summary };
export default Summary;

import { useToggleable } from '@swe/shared/hooks/use-toggleable';
import { useBreakpoint } from '@swe/shared/tools/media';
import Alert from '@swe/shared/ui-kit/components/alert';
import AnimatedHeight from '@swe/shared/ui-kit/components/animated-height';
import Box from '@swe/shared/ui-kit/components/box';
import { ChevronDownIcon, ChevronUpIcon, InfoIcon, QuestionIcon } from '@swe/shared/ui-kit/components/icon';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';
import Stack from '@swe/shared/ui-kit/components/stack';
import cl from 'clsx';

import React, { ReactNode, useEffect } from 'react';

import styles from './styles.module.scss';

import Limit from 'domains/cart/components/summary/components/limit';

import { SALE_TYPE_TO_LABEL } from 'domains/cart/constants';

import { PurchaseLimit } from 'entities/common/orders';
import { ProductSaleType } from 'entities/product/product';

type CardSummaryProps = {
  CheckoutButton: ReactNode;
  hasLimits?: boolean;
  isOutOfLimit?: boolean;
  limits?: PurchaseLimit[];
  patientName?: string;
  saleType?: ProductSaleType;
};

const CardSummary = ({
  CheckoutButton,
  hasLimits,
  isOutOfLimit = false,
  limits,
  patientName,
  saleType,
}: CardSummaryProps) => {
  const [isOpen, open, , toggle] = useToggleable(false);
  const trailIcon = isOpen ? ChevronUpIcon : ChevronDownIcon;
  const { desktop } = useBreakpoint();

  useEffect(() => {
    if (isOutOfLimit) {
      open();
    }
  }, [isOutOfLimit, open]);

  const renderLimitElement = () => {
    if (!hasLimits) return null;

    return (
      <SectionHeading
        size="sm"
        subHeading={patientName ? `For ${patientName}` : undefined}
        colorText={isOutOfLimit ? 'danger' : undefined}
        onClick={!isOutOfLimit && !desktop ? toggle : undefined}
        trailIcon={!isOutOfLimit && !desktop ? trailIcon : undefined}
        badge={
          isOutOfLimit
            ? {
                icon: QuestionIcon,
                color: 'danger',
                tooltip: (
                  <>
                    Legal limits are exceeded.
                    <br /> Please remove some products containing excessive category from the cart
                  </>
                ),
              }
            : undefined
        }
      >
        {isOutOfLimit ? 'Exceeded limits' : saleType ? `${SALE_TYPE_TO_LABEL[saleType]} Limits` : 'Purchase Limits'}
      </SectionHeading>
    );
  };

  const renderDesktopAlertElement = () => {
    if (!desktop) return null;

    return (
      <Alert
        icon={InfoIcon}
        color="neutral"
      >
        Purchase total, including taxes and discounts, will be shown at checkout
      </Alert>
    );
  };

  const renderLimitsElement = () => {
    if (!limits?.length) return null;

    return (
      <Stack spacing="sm">
        {limits.map((limit, idx) => (
          <Limit
            key={idx}
            name={limit.nameAbbr}
            current={limit.limitAmount}
            max={limit.maxAmount}
            abbreviation={limit.uomAbbr}
          />
        ))}
      </Stack>
    );
  };

  const limitsElement = renderLimitsElement();
  const limitElement = renderLimitElement();
  const desktopAlertElement = renderDesktopAlertElement();

  const stackLimitElement = (
    <Stack spacing="md">
      {limitsElement}
      {desktopAlertElement}
      {CheckoutButton}
    </Stack>
  );

  const desktopAnimatedHeightElement = (
    <AnimatedHeight expanded={desktop || isOpen}>{stackLimitElement}</AnimatedHeight>
  );

  if (!desktopAnimatedHeightElement && !limitElement) return null;

  return (
    <Box className={cl(!desktop && hasLimits && styles._purchase, isOpen && styles._purchase__opened)}>
      {limitElement}
      {desktopAnimatedHeightElement}
    </Box>
  );
};

export { CardSummary };
export default CardSummary;
